import "liquid-ajax-cart";

import Alpine from "alpinejs";
import AlpineCollapse from "@alpinejs/collapse";
import AlpineFocus from "@alpinejs/focus";
import AlpineMorph from "@alpinejs/morph";
import AlpineWire from "../js/alpine/plugins/wire";
import AlpineGlobals from "../js/alpine/index.js";
import helpers, { hasBodyClass } from "../js/helpers.js";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Swiper
import Swiper from "swiper";
import { Navigation, Thumbs } from "swiper/modules";
import "swiper/swiper-bundle.css";

// Attach Swiper and its modules to the window object
window.Swiper = Swiper;
window.Navigation = Navigation;
window.Thumbs = Thumbs;

window.swiper = new Swiper(".swiper-image-slideshow", {
  modules: [Navigation, Thumbs],
  slidesPerView: 1,
  navigation: {
    nextEl: ".swiper-next",
    prevEl: ".swiper-prev",
    disabledClass: "disabled",
  },
  thumbs: {
    swiper: {
      el: '.swiper-thumbnails',
      slidesPerView: 5,
      spaceBetween: 10,
      breakpoints: {
        768: {
          slidesPerView: 6,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 8,
          spaceBetween: 10,
        },
        1280: {
          slidesPerView: 10,
          spaceBetween: 10,
        }
      }
    }
  },
});

// Dynamic imports
hasBodyClass("product-template") && import("../js/prodify.js");

const ns = "slayed";

window.slayedNamespace = ns;
window[ns] = window[ns] || {};
window[ns].helpers = helpers;

for (const [key, value] of Object.entries(helpers)) {
    window[ns].helpers[key] = value;
}

// Register and initialize AlpineJS
window.Alpine = Alpine;

Alpine.plugin([AlpineCollapse, AlpineFocus, AlpineWire, AlpineMorph]);
AlpineGlobals.register(Alpine);
Alpine.start();

// Focal point images
document.addEventListener("DOMContentLoaded", function () {
    var images = document.querySelectorAll(".focal-point-image");
    images.forEach(function (image) {
        var focalX = parseFloat(image.getAttribute("data-focal-x")) || 0.5;
        var focalY = parseFloat(image.getAttribute("data-focal-y")) || 0.5;
        image.style.objectPosition = focalX + "% " + focalY + "%";
    });
});

//Hide the Shopify preview bar when in development
if (process.env.NODE_ENV === 'development') {
  window.addEventListener('DOMContentLoaded', () => {
    var css = '#preview-bar-iframe { display: none !important; }',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style')

    head.appendChild(style)

    style.appendChild(document.createTextNode(css))
  })
}

