export default {
    name: "dropdown",
    component() {
        return {
            open: false,
            toggle() {
                this.open = !this.open;
            },
            close() {
                this.open = false;
            },
            open() {
                this.open = true;
            },
        };
    },
};
